<template>
  <div class="home">
    <!-- <el-carousel height="150px" indicator-position="outside" arrow="never">
      <el-carousel-item v-for="item in bannerList" :key="item.id" :interval="5000">
        <img :src="item.picture" width="100%" height="100%" />
      </el-carousel-item>
    </el-carousel> -->
    <div v-if="bannerList.length">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in bannerList"
            :key="index"
            @click="handleC(item)"

          >
            <img
              :src="item.picture|fullPath"
              width="100%"
              height="auto"
            />
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swiper from "swiper";
import Swiper from "swiper";

import "swiper/dist/js/swiper";
import "swiper/dist/css/swiper.css";

import { getHomeBanner } from "@/api/index";

export default {
  data() {
    return {
      bannerList: [],
      slides: [
        { image: "path/to/image1.jpg", altText: "Image 1" },
        { image: "path/to/image1.jpg", altText: "Image 12" },
        { image: "path/to/image1.jpg", altText: "Image 13" },
        { image: "path/to/image2.jpg", altText: "Image 2" },
        // ...
      ],
      swiper: null,
      advertisementImg:""
    };
  },
  components: {
    // Swiper,
    // SwiperSlide
  },
  created() {},
  mounted() {
    this.getbanner();
    // new Swiper(".swiper-container", {
    //     slidesPerView: 1, // 每次只显示一个滑动项
    //     spaceBetween: 50,
    //     observer: true,
    //     observeParents: true,
    //     loop: true,
    //     pagination: {
    //       // 如果需要分页器
    //       el: ".swiper-pagination",
    //       clickable: true, // 点击分页器的指示点控制swiper切换
    //     },
    //     autoplay: {
    //       delay: 2000,
    //       disableOnInteraction: false,
    //       waitForTransition: true,
    //     },
    //   });
    
    // setTimeout(() => {
     
    // }, 200);
  },
  methods: {
    handleC(item) {
      // this.$message({
      //   message: "触发点击",
      //   type: "success",
      //   customClass: "log_success",
      // });
      console.log("轮播图", item.jumpLink);

      // window.location.href = "http://192.168.1.52:8080/welfare";
      if (item.jumpLink) {
        window.location.href = item.jumpLink;
      }
    },
    getbanner() {
      getHomeBanner().then((res) => {
        console.log(res,'轮播图');
        this.bannerList = res.data.data;
        this.$nextTick(() => {
          if (this.swiper) {
            this.swiper.update();
          } else {
            this.initSwiper();
          }
        });
      });
    },
    initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        slidesPerView: 1,
        spaceBetween: 50,
        observer: true,
        observeParents: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
          waitForTransition: true,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 95%;
  margin: 0 auto;
}
.swiper-container {
  width: 100%;
  height: 140px; /* 或者其他你需要的高度 */
  overflow: hidden;
  @media (max-width: 500px) {
    height: 100px;
  }
}
::v-deep .swiper-pagination-bullet {
  background: #323436;
}
::v-deep .swiper-pagination-bullet-active {
  background: #25f484;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.el-carousel {
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  /deep/ .el-carousel__button {
    // 指示器按钮
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: #323436;
  }
  /deep/ .is-active .el-carousel__button {
    // 指示器激活按钮
    background: #25f484;
  }

  /deep/ .el-carousel__container {
    height: 100%;
  }
}
</style>
