import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    showstate:false,
    user_info: {},
    websocketMessages: {},
    LOGIN_IS_SHOW: false,
    USER_INFO: "",
    battlefinallmessage: {},
    remotelogin: [],
    voicefile: new Audio(require("../assets/02.mp3")),
    // 是否显示广告
    ADVERT_IS_SHOW: false,
  },
  mutations: {
    playAudio(state){
      if(!state.voicefile.paused){
        state.voicefile.load()
        return 
      }
      state.voicefile.play()
    },
    loadAudio(state){
      
      state.voicefile.load()
    },
    USER_INFO(state, message) {
      state.USER_INFO = message;
    },
    LOGIN_IS_SHOW(state, message) {
      state.LOGIN_IS_SHOW = message;
    },
    addMessage(state, message) {
      state.websocketMessages = message;
    },
    battlefinall(state, message) {
      state.battlefinallmessage = message;
    },
    ADVERT_IS_SHOW(state, value) {
      state.ADVERT_IS_SHOW = value;
    },
  },
  actions: {
    processMessage({ commit }, message) {
      commit("addMessage", message);
    },
  },
});
