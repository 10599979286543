<template>
  <div class="main">
    <svg @click="handleClose" t="1705470289990" class="icon close" viewBox="0 0 1024 1024" version="1.1" p-id="3432"
      width="28" height="28">
      <path
        d="M898.844444 830.577778c22.755556 17.066667 22.755556 51.2 0 73.955555s-51.2 22.755556-73.955555 0L512 591.644444l-312.888889 312.888889c-11.377778 11.377778-22.755556 17.066667-39.822222 17.066667-11.377778 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-22.755556-51.2 0-73.955555l312.888889-312.888889-307.2-318.577778c-11.377778-5.688889-17.066667-22.755556-17.066667-34.133333 0-11.377778 5.688889-28.444444 17.066667-34.133334 22.755556-22.755556 51.2-22.755556 73.955555 0L512 443.733333l312.888889-312.888889c22.755556-22.755556 51.2-22.755556 73.955555 0s22.755556 51.2 0 73.955556l-312.888888 312.888889 312.888888 312.888889z"
        fill="#727477" p-id="3433" />
    </svg>
    <div class="advert" @click="goLogin">
      <img :src="img|fullPath" alt="" class="advert-img" />
    </div>
  </div>
</template>

<script>
// import browser from "@/utils/brouser";
// getToken
// import { setToken } from "../../../api/cookie";
// import { mapState } from "vuex";
export default {
  // 接收传值
  props: {
    img: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    // 关闭广告
    handleClose() {
      this.$bus.$emit("closeAdvert", false);
    },
    // 注册
    goLogin() {
      this.$bus.$emit("goLogin", 'zc');
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  background: rgba(0, 0, 0, 0.85);
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  

  .close {
    position: absolute;
    right: 77px;
    top: 25vh;
  }
  .advert {
    width: 60%;
    height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    .advert-img {
      width: 100%;
      height: auto;
    }
  }
}
</style>